<template>
  <div class="rcc-table-list">
    <div class="rcc-table-list__actions-wrapper">
      <rcc-radio-group :options="tableTypeRadioOptions" v-model="tableType" />

      <rcc-button v-if="editAccess" @click="$router.push({ name: 'suppliers-new' })">
        Добавить поставщика
      </rcc-button>
    </div>

    <div class="rcc-table-list__table-wrapper">
      <rcc-table
        :page.sync="page"
        :pageCount="pagesCount"
        :itemsPerPage="rowsOnTable"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isLoading="isDataLoading"
        @filters-changed="handleFiltersChanged"
      />
    </div>
  </div>
</template>

<script>
import Page from '@/mixins/page'
import TableList from '@/mixins/table-list'
import SuppliersApi from '@/api/suppliers'
import { objectToOptions } from '@/utils/get-options'
import headerData from './header'

import RccButton from 'Components/controls/rcc-button'
import RccTable from 'Components/table/rcc-table'
import RccRadioGroup from 'Components/controls/radio-group/rcc-radio-group.vue'

export default {
  mixins: [Page, TableList, SuppliersApi],

  components: {
    RccTable,
    RccButton,
    RccRadioGroup
  },

  data() {
    return {
      tableType: 'active'
    }
  },

  computed: {
    headerData() {
      return headerData[this.tableType]
    },

    tableTypeRadioOptions() {
      return objectToOptions(
        { object: this.$constants.status, keyName: 'value', valueName: 'label' }
      )
    },

    getSupplierMethod() {
      return this.tableType === 'active' ? this.getSuppliers : this.getBlockedSuppliers
    }
  },

  watch: {
    tableType() {
      this.page = 1
      this.loadData()
    }
  },

  methods: {
    loadData(filters) {
      this.isDataLoading = true

      this.getSupplierMethod({ page: this.page, per_page: this.rowsOnTable, ...filters })
        .then(({ pages, items }) => {
          if (!pages) {
            this.pagesCount = 1
            this.rows = []
            return
          }

          this.pagesCount = pages
          this.rows = items.map(item => (
            {
              ...item,
              status_changed_at: item.status_changed_at
                && this.$moment(item.status_changed_at).format('DD.MM.YYYY'),
              contract_type: this.$constants.contract[item.contract_type]
            }
          ))
        })
        .catch(() => {
          this.rows = []
        })
        .finally(() => { this.isDataLoading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-table-list {
  &__actions-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
</style>
